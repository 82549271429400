<template>
  <div style="overflow-y: auto; max-height: 100%">
    <el-row>
      <el-col :span="3">
        <el-button type="primary" size="small" @click="goBack">返回</el-button>
      </el-col>
      <el-col :span="3">
        <el-button type="primary" size="small" @click="submitForm">保存提交</el-button>
      </el-col>
      <el-col :span="10" style="text-align: center;">
        <h2>求职者添加</h2>
      </el-col>
    </el-row>
    <el-form label-position="left" label-width="auto" :model="form" ref="form">
      <el-row :gutter="50">
        <el-col :span="5">
          <el-form-item label="姓名:">
            <template #label>
              <span style="color: red">*</span> 姓名:
            </template>
            <el-input v-model="form.realName" placeholder="姓名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="手机号码:">
            <template #label>
              <span style="color: red">*</span> 手机号码:
            </template>
            <el-input v-model="form.phone" placeholder="手机号码"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="出生日期:">
            <template #label>
              <span style="color: red">*</span> 出生日期:
            </template>
            <el-date-picker
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                v-model="form.birthday"
                type="date"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="在职/离职">
            <template #label>
              <span style="color: red">*</span> 在职/离职:
            </template>
            <el-select v-model="form.onJob" placeholder="在职/离职">
              <el-option
                  v-for="item in onJobList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="5">
          <el-form-item label="专业">
            <template #label>
              <span style="color: red">*</span> 专业:
            </template>
            <el-select v-model="form.professional" placeholder="专业">
              <el-option
                  v-for="item in professionalList"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="性别:">
            <template #label>
              <span style="color: red">*</span> 性别:
            </template>
            <el-select v-model="form.sex" placeholder="性别：">
              <el-option
                  v-for="item in sexDictList"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="最高学历:">
            <template #label>
              <span style="color: red">*</span> 最高学历:
            </template>
            <el-select v-model="form.education" placeholder="最高学历">
              <el-option
                  v-for="item in educationList"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="全日制:">
            <el-radio v-model="form.fullTime" checked label="全日制">是</el-radio>
            <el-radio v-model="form.fullTime" label="非全日制">否</el-radio>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="4">
          <el-form-item label="期望月薪:">
            <template #label>
              期望月薪:
            </template>
            <el-input v-if="form.minSalary>0" v-model="form.minSalary" @focus="dialogInputMinSalary = true"
                      placeholder="薪资"></el-input>
            <el-button v-else @click="dialogInputMinSalary = true">面议</el-button>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="到岗时间:">
            <template #label>
              <span style="color: red">*</span> 最快到岗时间:
            </template>
            <el-select v-model="form.arrivalTime" placeholder="到岗时间">
              <el-option
                  v-for="item in arrivalTimeList"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <el-form-item label="用户类型:">
            <template #label>
              <span style="color: red">*</span> 用户类型:
            </template>
            <el-select v-model="form.userType" placeholder="用户类型:">
              <el-option
                  v-for="item in userTypeList"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="工作经验:">
            <template #label>
              <span style="color: red">*</span> 工作经验/年:
            </template>
            <el-input-number :min="0" :max="100" controls-position="right" v-model="form.workingYears" :step="1"
                             step-strictly></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="工作经验:">
            <template #label>
              <span style="color: red">*</span> 工作经验/月:
            </template>
            <el-input-number :min="0" :max="100" controls-position="right" v-model="form.workingMonths" :step="1"
                             step-strictly></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="7">
          <el-form-item label="技能证书:">
            <el-input type="textarea" :rows="3" v-model="form.certificate" placeholder="技能证书"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="获得荣誉:">
            <el-input type="textarea" :rows="3" v-model="form.honor" placeholder="所获荣誉"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="校园经历:">
            <el-input type="textarea" :rows="3" v-model="form.schoolExperience" placeholder="校园经历"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider content-position="left">教育经历</el-divider>
      <el-row type="flex" justify="center">
        <el-col :span="4">
          <el-button type="primary" size="small" @click="addStudyExperience">新增教育经历</el-button>
        </el-col>
      </el-row>
      <el-row v-for="(experience2, index2) in studyExperiences" :key="experience2.uuid" :gutter="0"
              style="margin-top: 1rem;">
        <el-col :span="6">
          <el-form-item label="开始日期:">
            <template #label>
              <span style="color: red">*</span> 开始日期:
            </template>
            <el-date-picker size="small"
                            value-format="yyyy-MM"
                            v-model="experience2.studyStartDate"
                            type="month"
                            placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="结束日期:">
            <template #label>
              <span style="color: red">*</span> 结束日期:
            </template>
            <el-date-picker size="small"
                            value-format="yyyy-MM"
                            v-model="experience2.studyEndDate"
                            type="month"
                            placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="学校名:">
            <template #label>
              <span style="color: red">*</span> 学校名:
            </template>
            <el-input size="small" v-model="experience2.schoolName" placeholder="学校名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="学历:">
            <template #label>
              <span style="color: red">*</span> 学历:
            </template>
            <el-select size="small" v-model="experience2.education" placeholder="学历">
              <el-option
                  v-for="item in educationList"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="3" style="text-align: center;">
          <el-button type="danger" size="small" @click="removeStudyExperience(index2)">删除</el-button>
        </el-col>
      </el-row>
      <el-divider content-position="left">工作经历</el-divider>
      <el-row type="flex" justify="center">
        <el-col v-if="!form.workExperiencesNull" :span="4">
          <el-button type="primary" size="small" @click="addWorkExperience">新增工作经历</el-button>
        </el-col>
        <el-col :span="4">
          <el-checkbox v-model="form.workExperiencesNull" label="无工作经验" border></el-checkbox>
        </el-col>
      </el-row>
      <div v-if="!form.workExperiencesNull">
        <el-row>
          <el-col :span="5">
            <el-form-item label="公司名:">
              <template #label>
                <span style="color:red">公司名</span>
              </template>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="职位名:">
              <template #label>
                <span style="color:red">职位名</span>
              </template>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="入职时间:">
              <template #label>
                <span style="color:red">入职时间</span>
              </template>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="离职时间:">
              <template #label>
                <span style="color:red">离职时间</span>
              </template>
            </el-form-item>
          </el-col>
<!--          <el-col :span="3">
            <el-form-item label="月薪:">
              <template #label>
                <span style="color:red">月薪</span>
              </template>
            </el-form-item>
          </el-col>-->

          <!--          <el-col :span="3">
                      <el-form-item label="证明人:">
                        <template #label>
                          <span style="color:red">证明人</span>
                        </template>
                      </el-form-item>
                    </el-col>
                    <el-col :span="3">
                      <el-form-item label="证明人:">
                        <template #label>
                          <span style="color:red">证明人电话</span>
                        </template>
                      </el-form-item>
                    </el-col>-->
        </el-row>
        <el-row v-for="(experience, index) in workExperiences" :key="experience.uuid" :gutter="0"
                style="margin-top: 1rem;">
          <el-col :span="5">
            <el-input v-model="experience.experienceCompanyName" placeholder="公司名"></el-input>
          </el-col>
          <el-col :span="5">
            <el-input v-model="experience.jobName" placeholder="职位名"></el-input>
          </el-col>
          <el-col :span="5">

            <el-date-picker v-model="experience.experienceStartTime" value-format="yyyy-MM" type="month"
                            placeholder="入职日期"></el-date-picker>
          </el-col>
          <el-col :span="5">
            <el-date-picker value-format="yyyy-MM" v-model="experience.experienceEndTime"
                            type="month"
                            placeholder="离职日期"></el-date-picker>
          </el-col>
<!--          <el-col :span="3">
            <el-input :min="1000" :max="10000000" v-model="experience.experienceSalary" type="number" placeholder="薪资"
                      :step="500"
                      step-strictly></el-input>
          </el-col>-->
          <!--          <el-col :span="3">
                      <el-input v-model="experience.experiencePeopleName" placeholder="证明人姓名"></el-input>
                    </el-col>
                    <el-col :span="3">
                      <el-input v-model="experience.experiencePeoplePhone" placeholder="证明人电话"></el-input>
                    </el-col>-->
          <el-col :span="3" style="text-align: center;">
            <el-button type="danger" size="small" @click="removeWorkExperience(index)">删除</el-button>
          </el-col>
        </el-row>
      </div>
    </el-form>


    <el-dialog
        title="输入期望薪资"
        :visible.sync="dialogInputMinSalary"
        @before-show="temp.minSalary=form.minSalary"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        width="20em">
      <el-row>
        <el-col>
          <el-input-number :min="1000" :max="10000000" controls-position="right" v-model="temp.minSalary"
                           :step="500" step-strictly></el-input-number>
        </el-col>
        <el-col style="margin-top: 10px;">
          <el-button @click="dialogInputMinSalary = false;form.minSalary=0;">面 议</el-button>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
              <el-button type="primary"
                         @click="dialogInputMinSalary = false; form.minSalary=temp.minSalary">确 定</el-button>
              </span>
    </el-dialog>

  </div>
</template>

<script>
import {sendJsonPostRequest} from "@/request/http";
import {v4 as uuidv4} from 'uuid';

export default {
  name: "AddWxUserManagement",
  data() {
    return {
      userTypeList: [],
      professionalList: [],
      educationList: [],
      arrivalTimeList: [],
      workExperiences: [
        {
          experienceCompanyName: '',
          jobName: '',
          experienceStartTime: null,
          experienceEndTime: null,
          experienceSalary: null,
          experiencePeopleName: null,
          experiencePeoplePhone: null,
          uuid: uuidv4()
        }
      ],
      studyExperiences: [
        {
          studyStartDate: null,
          studyEndDate: null,
          schoolName: null,
          education: null,
          uuid: uuidv4()
        }
      ],
      form: {
        schoolExperience: '',
        honor: '',
        certificate: '',
        userType: "3",
        realName: '',
        age: null,
        sex: null,
        fullTime: '全日制',
        minSalary: 1000,
        workingYears: 0,
        workingMonths: 0,
        education: null,
        professional: '1',
        arrivalTime: '随时到岗',
        onJob: '在职',
        workExperiencesNull: false,
      },
      sexDictList: [
        {
          dictValue: '女',
          dictLabel: '女'
        },
        {
          dictValue: '男',
          dictLabel: '男'
        },
        {
          dictValue: '未知',
          dictLabel: '未知'
        }
      ],
      onJobList: [
        {
          label: '在职',
          value: '在职'
        },
        {
          label: '离职',
          value: '离职'
        },
        {
          label: '应届毕业生',
          value: '应届毕业生'
        },
        {
          label: '在校生',
          value: '在校生'
        }
      ],
      dialogInputMinSalary: false,
      temp: {
        minSalary: 0,
      },
    }
  },
  methods: {
    goBack() {
      this.$router.push({path: '/appMain/wxUser'});
    },
    addWorkExperience() {
      const lastExperience = this.workExperiences[this.workExperiences.length - 1];
      if (!lastExperience.experienceCompanyName || !lastExperience.experienceCompanyName.trim() ||
          !lastExperience.jobName || !lastExperience.jobName.trim()) {
        this.$message.error("公司名和职位名必填");
        return;
      }
      this.workExperiences.push({
        experienceCompanyName: '',
        experienceStartTime: '',
        jobName: '',
        experienceEndTime: '',
        experienceSalary: '',
        experiencePeopleName: '',
        experiencePeoplePhone: '',
        uuid: uuidv4()
      });
    },
    removeWorkExperience(index) {
      if (this.workExperiences.length <= 1) {
        this.$message.error("至少保留一条工作经历");
        return;
      }
      this.workExperiences.splice(index, 1);
      this.saveDraft();
    },
    addStudyExperience() {
      const lastExperience = this.studyExperiences[this.studyExperiences.length - 1];
      const isEmpty = Object.values(lastExperience).some(value => value === null || value === undefined || value === '');
      if (isEmpty) {
        this.$message.error("请先填写完整当前的教育经历信息");
        return;
      }
      this.studyExperiences.push({
        studyStartDate: null,
        studyEndDate: null,
        schoolName: null,
        education: null,
        uuid: uuidv4()
      });
    },
    removeStudyExperience(index) {
      if (this.studyExperiences.length <= 1) {
        this.$message.error("至少保留一条教育经历");
        return;
      }
      this.studyExperiences.splice(index, 1);
      this.saveDraft();
    },
    submitForm() {
      if (this.form.birthday) {
        const birthDate = new Date(this.form.birthday);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        this.form.age = age;
      }

      if (this.form.workExperiencesNull) {
        this.form.workExperiences = null;
      } else {
        if (this.workExperiences.length > 0) {
          const lastExperience = this.workExperiences[this.workExperiences.length - 1];
          if (lastExperience) {
            if (lastExperience.experienceCompanyName == '' || lastExperience.jobName == '') {
              this.$message.error("公司名和职位名必填");
              return;
            }
          }
        }
        if (this.studyExperiences.length > 0) {
          const lastStudyExperience = this.studyExperiences[this.studyExperiences.length - 1];
          const isStudyEmpty = Object.values(lastStudyExperience).some(value => value === null || value === undefined || value === '');
          if (isStudyEmpty) {
            this.$message.error("请先填写完整当前的教育经历信息");
            return;
          }
        }
        this.form.workExperiences = this.workExperiences;
      }
      this.form.studyExperiences = this.studyExperiences;
      sendJsonPostRequest({
        url: '/sysWxUser/save',
        data: this.form,
      }).then(response => {
        if (response.data.responseCode === 200) {
          this.$message.success('添加成功');
          this.$router.push({path: '/appMain/wxUser'});
          this.clearDraft();
          this.resetForm();
        } else {
          this.$message.error('添加失败');
        }
      })
    },
    resetForm() {
      this.$refs.form.resetFields();
    },
    getDictMap() {
      this.professionalList = JSON.parse(localStorage.getItem('professionalList'));
      this.educationList = JSON.parse(localStorage.getItem('educationList'));
      this.arrivalTimeList = JSON.parse(localStorage.getItem('arrivalTimeList'));
      this.userTypeList = JSON.parse(localStorage.getItem('userTypeList'));
    },
    // 保存草稿到 localStorage
    saveDraft() {
      localStorage.setItem('formDraft', JSON.stringify(this.form));
      localStorage.setItem('workExperiencesDraft', JSON.stringify(this.workExperiences));
    },
    // 清除草稿
    clearDraft() {
      localStorage.removeItem('formDraft');
      localStorage.removeItem('workExperiencesDraft');
    },
    // 从 localStorage 恢复草稿
    loadDraft() {
      const formDraft = localStorage.getItem('formDraft');
      const workExperiencesDraft = localStorage.getItem('workExperiencesDraft');
      if (formDraft) {
        this.form = JSON.parse(formDraft);
      }
      if (workExperiencesDraft) {
        this.workExperiences = JSON.parse(workExperiencesDraft);
      }
    },

  },
  created() {
    this.getDictMap();
    this.loadDraft();
  },
  watch: {
    // 监听 form 和 workExperiences 的变化并保存草稿
    form: {
      deep: true,
      handler() {
        this.saveDraft();
      }
    },
    workExperiences: {
      deep: true,
      handler() {
        this.saveDraft();
      }
    }
  }
}
</script>

<style scoped>
/* General Page Layout */
h2 {
  font-weight: bold;
  color: #333;
  margin: 0;
}

/* Spacing and Alignment */
.el-row {
  margin-bottom: 20px;
}

.el-col {
  padding: 0 10px;
}

.el-form {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.el-divider {
  border-color: #e4e4e4;
  margin: 20px 0;
}

/* Buttons */
.el-button {
  margin-right: 10px;
}

.el-button.primary {
  background-color: #409eff;
  color: white;
}

.el-button.danger {
  background-color: #f56c6c;
  color: white;
}

/* Form Items */
.el-form-item {
  margin-bottom: 16px;
}

.el-form-item label {
  font-weight: bold;
  color: #666;
}

/* Input Styling */
.el-input,
.el-select,
.el-input-number,
.el-date-picker {
  width: 100%;
}

.el-input-number {
  text-align: right;
}

/* Error Messages */
.el-message {
  z-index: 9999;
}

/* Additional Spacing for Action Buttons */
.action-buttons {
  margin-top: 20px;
  text-align: right;
}

/* Work/Study Experience Rows */
.experience-row {
  margin-bottom: 10px;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  padding: 10px;
  background-color: #fff;
}

.experience-row .el-button {
  margin-left: 10px;
}

</style>
