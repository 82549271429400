<template>
  <div class="home">
    <el-row>
      <el-col :span="12">
        <div id="echarts_pie" class="echartsPie"></div>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="24">
        <div id="echarts_bar" class="echartsBar"></div>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import * as echarts from 'echarts';
import {sendJsonPostRequest} from "@/request/http";

export default {
  name: 'HomeManagement',
  data() {
    return {
      myEcharts: null
    }
  },
  methods: {
    initEcharts() {
      var echartsBar = echarts.init(document.getElementById('echarts_bar'));
      var echartsPie = echarts.init(document.getElementById('echarts_pie'));
      //获取岗位统计信息
      sendJsonPostRequest({
        url: '/home/postStatistics',
        data: {}
      }).then(response => {
        if (response.data.responseCode === 200) {

          let postStatistics = response.data.data;
          echartsPie.setOption({
            title: {
              text: '招聘职位统计图', // 图标标题
            },
            series: [{
              name: '招聘职位统计图',
              type: 'pie', // 图类型-饼图
              radius: '55%', // 图占容器大小
              data: [ // 饼图数据格式
                {
                  value: postStatistics[0],
                  name: '在招岗位',
                  itemStyle: {
                    color: 'skyblue', // 某一项的颜色
                  }
                },
                {
                  value: postStatistics[1],
                  name: '下架岗位',
                  itemStyle: {
                    color: 'orange'
                  }
                }
              ],
              itemStyle: {
                normal: {
                  shadowBlur: 20, // 阴影散发长度
                  shadowColor: 'rgba(0, 0, 0, 0.5)', // 阴影颜色
                  label: {
                    show: true, // 是否显示标识文字
                    fontSize: 16, // 字体大小，单位px
                    formatter: '{b} : {c} ({d}%)', // a=>series.name b=>series.data.name c=>series.data.value, d=>占比
                  }
                }
              }
            }]
          });
        }
      });
      //获取求职用户统计信息
      sendJsonPostRequest({
        url: '/home/wxUserStatistics',
        data: {}
      }).then(response => {
        if (response.data.responseCode === 200) {
          console.log(JSON.stringify(response.data.data))
          echartsBar.setOption({
            title: {
              text: '求职者统计图', // 图标标题
            },
                xAxis: {
                  data: response.data.data.xData,
                },
                yAxis: {},
                series: [
                  {
                    type: 'bar',
                    data: response.data.data.yData
                  }
                ]
              }
          )
        }
      });
    }
  },
  mounted() {
    this.initEcharts()
  }
}
</script>
<style scoped>
.echartsPie {
  display: inline-block;
  width: 100vh;
  height: 35vh;
  margin-left: 50px;
/*  border: 1px solid #c0c0c0;*/
}
.echartsBar {
  display: inline-block;
  width: 100%;
  height: 50vh;
  margin-left: 50px;
/*  border: 1px solid #c0c0c0;*/
}
</style>
