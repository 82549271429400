<template>
  <div class="user-detail">
    <!-- 顶部按钮 -->
    <el-row class="toolbar">
      <el-col :span="3">
        <el-button type="primary" size="small" @click="goBack">返回</el-button>
      </el-col>
      <el-col :span="3">
        <!--        <el-button type="primary" size="small" @click="exportWord">导出简历</el-button>-->
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <div ref="content">
      <!-- 基本信息展示 -->
      <section>
        <h3>基本信息</h3>
        <el-row class="info-row" :gutter="50">
          <el-col :span="5">姓名：<span class="info">{{ userDetails.realName }}</span></el-col>
          <el-col :span="5">联系方式：<span class="info">{{ userDetails.phone }}</span></el-col>
          <el-col :span="5">出生日期：<span class="info">{{ userDetails.birthday }}</span></el-col>
          <el-col :span="5">在职/离职：<span class="info">{{ userDetails.onJob }}</span></el-col>
        </el-row>
        <el-row class="info-row" :gutter="50">
          <el-col :span="5">专业：<span class="info">{{ professionalLabel }}</span></el-col>
          <el-col :span="5">性别：<span class="info">{{ userDetails.sex }}</span></el-col>
          <el-col :span="5">学历：<span class="info">{{ userDetails.education }}</span></el-col>
          <el-col :span="5">全日制：<span class="info">{{ userDetails.fullTime }}</span></el-col>
        </el-row>
        <el-row class="info-row" :gutter="50">

          <el-col v-if="userDetails.workExperiencesNull" :span="5">期望月薪：<span class="info">面议</span></el-col>
          <el-col :span="5">期望月薪：<span class="info">{{
              userDetails.minSalary == 0 ? '面议' : userDetails.minSalary
            }}</span></el-col>

          <el-col :span="5">最快到岗时间：<span class="info">{{ arrivalTimeLabel }}</span></el-col>
          <el-col :span="5">年龄：<span class="info">{{ userDetails.age }}</span></el-col>

        </el-row>
        <el-row class="info-row" :gutter="50" v-show="!userDetails.workExperiencesNull">
          <el-col :span="5">工作经验/年：<span class="info">{{ userDetails.workingYears }}</span></el-col>
          <el-col :span="5">工作经验/月：<span class="info">{{ userDetails.workingMonths }}</span></el-col>
        </el-row>
        <el-row class="info-row" :gutter="50">
          <el-col v-show="userDetails.workExperiencesNull" :span="5">工作经验：<span class="info">无工作经验</span></el-col>
          <el-col :span="5">技能证书：<span
              class="info">{{ userDetails.certificate == null ? '无' : userDetails.certificate }}</span></el-col>
          <el-col :span="5">获得荣誉：<span class="info">{{ userDetails.honor == null ? '无' : userDetails.honor }}</span>
          </el-col>
          <el-col :span="5">校园经历：<span
              class="info">{{ userDetails.schoolExperience == null ? '无' : userDetails.schoolExperience }}</span>
          </el-col>
        </el-row>
      </section>
      <el-divider></el-divider>

      <!-- 工作经历 -->
      <section>
        <h3>工作经历</h3>
        <div v-for="(experience, index) in userDetails.workExperiences" :key="index" class="experience-block">
          <el-row :gutter="50">
            <el-col :span="5">公司名：<span class="info">{{ experience.experienceCompanyName }}</span></el-col>
            <el-col :span="5">职位：<span class="info">{{ experience.jobName }}</span></el-col>
            <el-col :span="5">入职日期：<span class="info">{{ dateFormat(experience.experienceStartTime) }}</span></el-col>
            <el-col :span="5">离职日期：<span class="info">{{ dateFormat(experience.experienceEndTime) }}</span></el-col>
          <!-- <el-col :span="5">月薪：<span class="info">{{ experience.experienceSalary }}</span></el-col>-->
          </el-row>
          <!--          <el-row :gutter="50" class="secondary-info">
                      <el-col :span="5">证明人：<span class="info">{{ experience.experiencePeopleName }}</span></el-col>
                      <el-col :span="5">证明人电话：<span class="info">{{ experience.experiencePeoplePhone }}</span></el-col>
                    </el-row>-->
        </div>
      </section>
      <el-divider></el-divider>

      <!-- 教育经历 -->
      <section>
        <h3>教育经历</h3>
        <div v-for="(experience, index) in userDetails.studyExperiences" :key="index" class="education-block">
          <el-row :gutter="50">
            <el-col :span="5">学校名：<span class="info">{{ experience.schoolName }}</span></el-col>
            <el-col :span="5">教育开始日期：<span class="info">{{ dateFormat(experience.studyStartDate) }}</span></el-col>
            <el-col :span="5">教育结束日期：<span class="info">{{ dateFormat(experience.studyEndDate) }}</span></el-col>
            <el-col :span="5">学历：<span class="info">{{ experience.education }}</span></el-col>
          </el-row>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {sendJsonPostRequest} from "@/request/http";
import htmlDocx from "html-docx-js/dist/html-docx";
import {saveAs} from "file-saver";

export default {
  name: "UserDetail",
  data() {
    return {
      userDetailsMap: {}, // 用户类型映射表
      userDetails: {}, // 用户详细信息
    };
  },
  methods: {
    // 导出简历（示例）
    exportWord() {
      const content = this.$refs.content.innerHTML;
      const docx = htmlDocx.asBlob(content);
      saveAs(docx, "output.docx");
    },
    // 日期格式化
    dateFormat(str) {
      if (!str) return "";
      return str.split(" ")[0]; // 提取日期部分
    },
    // 返回上一页
    goBack() {
      this.$router.replace({path: "/appMain/wxUser"});
    },
    // 获取用户详细信息
    getUserDetails() {
      sendJsonPostRequest({
        url: "/sysWxUser/detail",
        data: {id: this.$route.query.id},
      }).then((response) => {
        if (response.data.responseCode === 200) {
          this.userDetails = response.data.data;
        }
      });
    },
  },
  created() {
    this.userDetailsMap = JSON.parse(localStorage.getItem("userType"));
    this.getUserDetails();
  },
  computed: {
    // 到岗时间标签
    arrivalTimeLabel() {
      let item = JSON.parse(localStorage.getItem("arrivalTime"));
      return item[this.userDetails.arrivalTime];
    },
    // 专业标签
    professionalLabel() {
      let item = JSON.parse(localStorage.getItem("professional"));
      return item[this.userDetails.professional];
    },
  },
};
</script>

<style scoped>
/* 顶部工具栏 */
.toolbar {
  margin-bottom: 20px;
}

/* 信息行样式 */
.info-row {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.5;
}

/* 信息文本样式 */
.info {
  font-weight: bold;
  color: #333;
}

/* 工作经历、教育经历块 */
.experience-block,
.education-block {
  margin-bottom: 15px;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}

.secondary-info {
  margin-top: 10px;
  font-size: 12px;
  color: #666;
}
</style>
