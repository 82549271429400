<template>
  <el-row>
    <span>保存</span>
    <area-cascader size="medium" type="text" v-model="selected" :data="pca" @change="handleChange"></area-cascader>
  </el-row>

</template>

<script>

export default {
  name: "AddCompanyManagement",
  data() {
    return {
      selected: [],
    }
  },
  methods: {
    handleChange() {
      console.log(this.selected);
    }
  },
  created() {
  }
}
</script>

<style scoped>

</style>
