<template>
  <div style="overflow-y: auto; max-height: 100%">
    <el-row :gutter="20" style="display: flex; align-items: center;">
      <el-col :span="4">
        <el-button type="primary" size="small" @click="goBack">返回</el-button>
      </el-col>
      <el-col :span="4">
        <el-button type="primary" size="small" @click="submitForm">保存</el-button>
      </el-col>
    </el-row>
    <el-form style="width: 100%;margin-top: 1rem;" ref="form" :model="form"
             label-width="120px">
      <el-row type="flex" justify="center" align="middle" class="content">
        <el-col :span="11" style="margin-top: 1rem;">
          <el-form-item label="姓名:">
            <el-input clearable v-model="form.realName" style="width: 75%" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="联系方式:">
            <el-input clearable v-model="form.phone" style="width: 75%" placeholder="请输入手机号"></el-input>
          </el-form-item>
<!--          <el-form-item label="身份证号码:">
            <el-input clearable v-model="form.identityCardNum" style="width: 75%" placeholder="请输入身份证号码"></el-input>
          </el-form-item>-->
          <el-form-item label="性别:">
            <el-select v-model="form.sex" placeholder="请选择" style="width: 75%">
              <el-option label="男" value="男"></el-option>
              <el-option label="女" value="女"></el-option>
              <el-option label="未知" value="未知"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学历:">
            <el-select v-model="form.education" placeholder="请选择" style="width: 75%">
              <el-option v-for="item in educationList" :key="item.dictValue" :label="item.dictLabel"
                         :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="全日制:">
            <el-select v-model="form.fullTime" placeholder="请选择" style="width: 75%">
              <el-option label="全日制" value="全日制"></el-option>
              <el-option label="非全日制" value="非全日制"></el-option>
              <el-option label="未知" value="未知"></el-option>
            </el-select>
          </el-form-item>
            <el-form-item label="工作经验/年:">
              <el-input-number :min="0" :max="100" controls-position="right" v-model="form.workingYears" :step="1"
                               step-strictly></el-input-number>
            </el-form-item>
            <el-form-item label="工作经验/月:">
              <el-input-number :min="0" :max="100" controls-position="right" v-model="form.workingMonths" :step="1"
                               step-strictly></el-input-number>
            </el-form-item>
        </el-col>
        <el-col :span="13">
          <el-form-item label="出生日期:">
            <el-date-picker style="width: 65%;"
                            v-model="form.birthday"
                            type="date"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="期望薪资:">
            <el-input clearable v-model="form.minSalary" min=1000 max=1000000 type="number" step="500"
                      style="width: 65%"
                      placeholder="月薪"></el-input>
          </el-form-item>
          <el-form-item label="最快到岗时间:">
            <el-select v-model="form.arrivalTime" placeholder="请选择" style="width: 65%;">
              <el-option v-for="item in arrivalTimeList" :key="item.dictValue" :label="item.dictLabel"
                         :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用户类型:">
            <el-select v-model="form.userType" placeholder="请选择" style="width: 65%;">
              <el-option v-for="item in userTypeList" :key="item.dictValue" :label="item.dictLabel"
                         :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="在离职">
            <el-select v-model="form.onJob" placeholder="请选择" style="width: 65%">
              <el-option label="在职" value="在职"></el-option>
              <el-option label="离职" value="离职"></el-option>
              <el-option label="应届毕业生" value="应届毕业生"></el-option>
              <el-option label="在校生" value="在校生"></el-option>
            </el-select>

              <el-form-item label="技能证书:">
                <el-input type="textarea" :rows="3" v-model="form.certificate" placeholder="技能证书"></el-input>
              </el-form-item>


              <el-form-item label="获得荣誉:">
                <el-input type="textarea" :rows="3" v-model="form.honor" placeholder="所获荣誉"></el-input>
              </el-form-item>


              <el-form-item label="校园经历:">
                <el-input type="textarea" :rows="3" v-model="form.schoolExperience" placeholder="校园经历"></el-input>
              </el-form-item>

          </el-form-item>
          <!--          <el-form-item label="年龄:">
                      <el-input clearable v-model="form.age" min=10 max=100 type="number" step="1"
                                style="width: 65%"
                                placeholder="年龄"></el-input>
                    </el-form-item>-->
        </el-col>
      </el-row>
    </el-form>
    <el-divider content-position="center">编辑教育经历</el-divider>
    <el-row type="flex" justify="center">
      <el-col :span="4">
        <el-button type="primary" size="small" @click="addStudyExperience">新增教育经历</el-button>
      </el-col>
    </el-row>
    <el-form label-width="120px" :model="form"  class="content">
      <el-row v-for="(experience,index) in form.studyExperiences" :key="experience.uuid" :gutter="5"
              style="margin-top: 1rem;display:flex; justify-content: center;align-items: center;">
        <el-col :span="5">
          <el-form-item label="开始日期:" size="small">
            <template #label>
              <span style="color: red">*</span> 开始日期:
            </template>
            <el-date-picker
                value-format="yyyy-MM"
                v-model="experience.studyStartDate"
                type="month"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="结束日期:" size="small">
            <template #label>
              <span style="color: red">*</span> 结束日期:
            </template>
            <el-date-picker
                value-format="yyyy-MM"
                v-model="experience.studyEndDate"
                type="month"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="学校名:" size="small">
            <template #label>
              <span style="color: red">*</span> 学校名:
            </template>
            <el-input v-model="experience.schoolName" placeholder="学校名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="学历:" size="small">
            <template #label>
              <span style="color: red">*</span> 学历:
            </template>
            <el-select v-model="experience.education" placeholder="学历">
              <el-option
                  v-for="item in educationList"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="3" style="display: flex;justify-content: center;">
          <el-button type="danger" size="small" @click="removeStudyExperience(index)">删除</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-divider content-position="center">编辑工作经历</el-divider>
    <el-row  type="flex" justify="center">
      <el-col :span="4"  v-if="!form.workExperiencesNull">
        <el-button type="primary" size="small" @click="addWorkExperience">新增工作经历</el-button>
      </el-col>
      <el-col :span="4">
        <el-checkbox v-model="form.workExperiencesNull" label="无工作经验" border></el-checkbox>
      </el-col>
    </el-row>
    <el-form v-if="!form.workExperiencesNull" label-width="120px" :model="form" class="content">
      <el-row v-for="(experience, index) in form.workExperiences" :key="experience.uuid"
              style="margin-top: 1rem;display:flex; justify-content: center;align-items: center;">
        <el-col :span="4">
          <el-form-item label-width="80px" label="公司名:" size="small">
            <el-input v-model="experience.experienceCompanyName" placeholder="公司名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label-width="80px" label="职位名:" size="small">
            <el-input v-model="experience.jobName" placeholder="职位名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label-width="80px" label="开始日期:" size="small">
            <el-date-picker
                value-format="yyyy-MM"
                v-model="experience.experienceStartTime"
                type="month"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label-width="80px" label="结束日期:" size="small">
            <el-date-picker
                value-format="yyyy-MM"
                v-model="experience.experienceEndTime"
                type="month"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <!--        <el-col :span="5">
                  <el-form-item label-width="80px" label="证明人:" size="small">
                    <el-input v-model="experience.experiencePeopleName" placeholder="姓名"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item label-width="70px" label="电话:" size="small">
                    <el-input v-model="experience.experiencePeoplePhone" placeholder="证明人电话"></el-input>
                  </el-form-item>
                </el-col>-->

        <el-col :span="3" label-width="60px" style="display: flex;justify-content: center">
          <el-button type="danger" size="small" @click="removeWorkExperience(index)">删除</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>

</template>

<script>
import {sendJsonPostRequest} from "@/request/http";

export default {
  name: "EditWxUserManagement",
  data() {
    return {
      form: {},
      educationList: [],
      userTypeList: [],
      arrivalTimeList: []
    }
  },
  methods: {
    addStudyExperience() {
      if (this.form.studyExperiences.length > 0) {
        const checkField = ['education', 'schoolName', 'studyEndDate', 'studyStartDate'];
        const lastExperience = this.form.studyExperiences[this.form.studyExperiences.length - 1];
        const isEmpty = checkField.some(field =>
            lastExperience[field] === null ||
            lastExperience[field] === undefined ||
            lastExperience[field] === ''
        );
        if (isEmpty) {
          this.$message.error("请先填写完整当前的教育经历信息");
          return;
        }
      }
      this.form.studyExperiences.push({
        studyStartDate: null,
        studyEndDate: null,
        schoolName: null,
        education: null,
        uuid: Date.now()
      })
    },
    addWorkExperience() {
      if (this.form.workExperiences.length > 0) {
        const checkField = ['experiencePeoplePhone', 'experiencePeopleName', 'jobName', 'experienceStartTime', 'experienceStartTime', 'experienceEndTime'];
        const lastExperience = this.form.workExperiences[this.form.workExperiences.length - 1];
        const isEmpty = checkField.some(field =>
            lastExperience[field] === null ||
            lastExperience[field] === undefined ||
            lastExperience[field] === ''
        );
        if (isEmpty) {
          this.$message.error("请先填写完整当前的工作经历信息");
          return;
        }
      }
      this.form.workExperiences.push({
        experienceCompanyName: null,
        experienceEndTime: null,
        experienceStartTime: null,
        jobName: null,
        experiencePeopleName: null,
        experiencePeoplePhone: null,
        uuid: Date.now()
      })
    },
    removeStudyExperience(index) {
      this.form.studyExperiences.splice(index, 1);
    },
    removeWorkExperience(index) {
      this.form.workExperiences.splice(index, 1);
    },
    goBack() {
      this.$router.replace({path: '/appMain/wxUser'});
    },
    getDictList() {
      this.educationList = JSON.parse(localStorage.getItem('educationList'));
      this.userTypeList = JSON.parse(localStorage.getItem('userTypeList'));
      this.arrivalTimeList = JSON.parse(localStorage.getItem('arrivalTimeList'));
    },
    submitForm() {
      if (this.form.birthday) {
        const birthDate = new Date(this.form.birthday);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        this.form.age = age;
      }
      if (this.form.workExperiencesNull) {
        this.form.experienceExperiences = [];
      }
      sendJsonPostRequest({
        url: '/sysWxUser/update',
        data: this.form,
      }).then(response => {
        if (response.data.responseCode === 200) {
          this.$message.success('修改成功');
          this.$router.replace({path: '/appMain/wxUser'});
        }
      })
    },
    getUserDetails() {
      sendJsonPostRequest({
        url: '/sysWxUser/detail',
        data: {id: this.$route.query.id},
      }).then(response => {
        if (response.data.responseCode === 200) {
          this.form = response.data.data;
        }
      })
    }
  },
  created() {
    this.getDictList();
    this.getUserDetails();
  }
}
</script>

<style scoped>
.content {
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  line-height: 100px;
  border-radius: 10px;
}
</style>
