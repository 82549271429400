<template>
  <div class="block">
    <el-dialog title="添加区域" :visible.sync="appendDialogShow" width="30%" :show-close="false"
               :close-on-press-escape="false"
               :close-on-click-modal="false">
      <el-form :model="appendForm" label-width="100px">
        <el-form-item label="区域名称">
          <template #label>
            <span style="color: red">*</span> 区域名称
          </template>
          <el-input v-model="appendForm.label"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="appendDialogShow = false">取 消</el-button>
        <el-button type="primary" @click="append">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="编辑区域" :visible.sync="editDialogShow" width="30%" :show-close="false"
               :close-on-press-escape="false"
               :close-on-click-modal="false">
      <el-form :model="editForm" label-width="100px">
        <el-form-item label="区域名称">
          <template #label>
            <span style="color: red">*</span> 区域名称
          </template>
          <el-input v-model="editForm.label"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogShow = false">取 消</el-button>
        <el-button type="primary" @click="edit">确 定</el-button>
      </span>
    </el-dialog>
    <el-row>
      <el-col :span="8">
        <p>
          区域代理商树
        </p>
      </el-col>
      <!--     <el-col span="16">
            <el-button type="primary" @click="getAreaTree">保存</el-button>
           </el-col>-->
    </el-row>
    <el-tree
        :data="treeData"
        node-key="id"
        :highlight-current="true"
        :show-checkbox="false"
        :indent="50"
        :expand-on-click-node="false"
        :render-content="renderContent">
    </el-tree>
  </div>
</template>

<script>
import {sendJsonPostRequest} from "@/request/http";


export default {
  name: "AreaTreeManagement",
  data() {
    return {
      treeData: [],
      data: null,
      editDialogShow: false,
      appendDialogShow: false,
      appendForm: {},
      editForm: {},
    };
  },
  methods: {
    appendDialog(data) {
      this.data = data;
      this.appendDialogShow = true;
    },
    editDialog(data) {
      this.data = data;
      this.editForm = {
        id: data.id,
        label: data.label
      }
      this.editDialogShow = true;
    },
    append() {
      this.appendForm.parentId = this.data.id;
      sendJsonPostRequest({
        url: '/agent/addNode',
        data: this.appendForm,
      }).then(response => {
        if (response.data.responseCode === 200) {
          this.getAreaTree();
        } else if (response.data.responseCode === 602) {
          this.$message({
            message: response.data.responseMessage,
            type: 'warning'
          });
        }
        this.appendDialogShow = false;
        this.appendForm = {};
        this.data = null;
      });
    },
    edit() {
      this.editForm.id = this.data.id;
      sendJsonPostRequest({
        url: '/agent/editNode',
        data: this.editForm,
      }).then(response => {
        if (response.data.responseCode === 200) {
          this.getAreaTree();
        } else if (response.data.responseCode === 602) {
          this.$message({
            message: response.data.responseMessage,
            type: 'warning'
          });
        }
        this.editDialogShow = false;
        this.editForm = {};
        this.data = null;
      });
    },
    removeDialog(node, data) {
      // 判断是否有子节点
      if (node.data.children && node.data.children.length > 0) {
        this.$message({
          message: '请先删除该节点的子节点',
          type: 'warning'
        });
        return;
      }
      /* const parent = node.parent;
       const children = parent.data.children || parent.data;
       const index = children.findIndex(d => d.id === data.id);
       children.splice(index, 1);*/
      this.$confirm('此操作将永久删除该节点, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
            // 用户点击 "确定" 的逻辑
            sendJsonPostRequest({
              url: '/agent/editNode',
              data: { id: data.id, expire: 1 }
            }).then(response => {
              if (response.data.responseCode === 200) {
                this.$message({
                  message: '删除成功',
                  type: 'success'
                });
                this.getAreaTree();
              } else if (response.data.responseCode === 602) {
                this.$message.error(response.data.responseMessage);
              } else {
                console.log(response.data.message);
              }
            });
          })
          .catch(() => {
            // 用户点击 "取消" 或其他异常的处理
            this.$message({
              message: '操作已取消',
              type: 'info'
            });
          });
    },
    renderContent(h, {node, data}) {
      return (
          <span class="custom-tree-node">
            <span>{node.label}</span>
            <span>
              <el-button type="success" size="mini" circle on-click={() => this.appendDialog(data)}>添加</el-button>
              <el-button type="success" size="mini" circle on-click={() => this.editDialog(data)}>编辑</el-button>
              <el-button type="danger" size="mini" circle on-click={() => this.removeDialog(node, data)}>删除</el-button>
            </span>
          </span>);
    },
    getAreaTree() {
      sendJsonPostRequest({
        url: '/agent/areaTree',
        data: {},
      }).then(response => {
        this.treeData = response.data.data;
      })
    }
  },
  created() {
    this.getAreaTree();
  }
}
</script>

<style scoped>

</style>
