<template>
  <div ref="content">
    <el-row type="flex" :gutter="20" class="row-style">
      <el-col :xs="6" :sm="6" :md="6" :lg="4" :xl="3">
        <el-input v-model="searchUserName" clearable placeholder="请输入用户名"></el-input>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="4" :xl="3">
        <el-input v-model="searchCreateBy" clearable placeholder="请输入收集者用户名"></el-input>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="4" :xl="2">
        <el-button type="primary" @click="search">搜索求职者</el-button>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="4" :xl="2">
        <el-button type="primary" @click="addShow" style="text-align: center">新增求职者</el-button>
      </el-col>
    </el-row>
    <el-dialog
        title="提示"
        :visible.sync="delVisible"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        width="30%">
      <span>删除用户</span>
      <span slot="footer" class="dialog-footer">
    <el-button @click="delVisible = false">取 消</el-button>
    <el-button type="primary" @click="del">确 定</el-button>
  </span>
    </el-dialog>
    <el-table
        :data="tableData" :row-class-name="tableRowClassName"
        style="width: 100%;overflow: scroll;margin-top: 1rem;">
      <el-table-column
          fixed
          prop="realName"
          label="真实姓名">
      </el-table-column>
      <el-table-column
          fixed
          prop="sex"
          label="性别">
      </el-table-column>
      <el-table-column
          fixed
          prop="professional"
          label="专业">
        <template v-slot="scope">
          {{
            professionalDictMap[scope.row.professional] === undefined ? '/' : professionalDictMap[scope.row.professional]
          }}
        </template>
      </el-table-column>
      <el-table-column
          fixed
          prop="workingYears"
          label="工作经验">
        <template v-slot="scope">
          {{ calculation(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column
          fixed
          prop="arrivalTime"
          label="最快到岗时间">
      </el-table-column>
      <el-table-column
          fixed
          prop="phone"
          label="联系方式">
      </el-table-column>
      <el-table-column
          fixed
          prop="userType"
          label="用户类型">
        <template v-slot="scope">
          {{ userTypeDictMap[scope.row.userType] }}
        </template>
      </el-table-column>
      <el-table-column
          fixed
          prop="onJob"
          label="在职/离职">
      </el-table-column>
      <el-table-column
          fixed
          prop="createBy"
          label="收集人">
        <template v-slot="scope">
          {{
            scope.row.createBy === '' ? '/' : scope.row.createBy
          }}
        </template>
      </el-table-column>
      <el-table-column
          label="详情">
        <template v-slot="scope">
          <el-button @click="detailShow(scope.row)" type="primary">详情</el-button>
        </template>
      </el-table-column>
      <el-table-column
          label="编辑">
        <template v-slot="scope">
          <el-button @click="updateShow(scope.row)" type="primary">编辑</el-button>
        </template>
      </el-table-column>
      <el-table-column
          label="编辑">
        <template v-slot="scope">
          <el-button @click="del(scope.row.id)" type="danger">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        layout="prev, pager, next"
        :current-page="currentPage"
        :page-size="pageSize"
        :total="total"
        @current-change="handlePageChange">
    </el-pagination>
  </div>
</template>

<script>

import {sendJsonPostRequest} from "@/request/http";

export default {
  name: "WxUserManagement",
  data() {
    return {
      searchCreateBy: '',
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      dialogVisible: false,
      handleType: '',
      searchUserName: '',
      form: {},
      delId: null,
      delVisible: false,
      professionalDictMap: {},
      userTypeDictMap: {},
      companyNatureDictMap: {},
      arrivalTimeDictMap: {}
    }
  },
  created() {
    // 获取字典
    this.getDictList();
    this.fetchData();
  },
  methods: {
    calculation(row) {
      if (row.workExperiencesNull) {
        return '无经验';
      } else {
        return row.workingYears + '年' + row.workingMonths + '个月';
      }
    },
    getDictList() {
      this.professionalDictMap = JSON.parse(localStorage.getItem('professional'));
      this.userTypeDictMap = JSON.parse(localStorage.getItem('userType'));
      this.arrivalTimeDictMap = JSON.parse(localStorage.getItem('arrivalTime'));
    },
    deleteShow(row) {
      this.delVisible = true;
      this.delId = row.id;
    },
    fetchData() {
      sendJsonPostRequest({
        url: '/sysWxUser/page',
        data: {'pageNum': this.currentPage, 'pageSize': this.pageSize},
      }).then(response => {
        if (response.data.responseCode === 200) {
          this.tableData = response.data.data.records;
          this.total = response.data.data.total;
        } else {
          console.log(response.data.message);
        }
      })
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    addShow() {
      this.$router.push(
          {
            path: '/appMain/addWxUser'
          }
      );
    },
    submitForm() {
      if (this.handleType === 'add') {
        sendJsonPostRequest({
          url: '/messageTemplate/wxSave',
          data: this.form,
        }).then(response => {
          if (response.data.responseCode === 200) {
            this.dialogVisible = false;
            this.fetchData();
          } else {
            console.log(response.data.message);
          }
        })
      } else if (this.handleType === 'update') {
        sendJsonPostRequest({
          url: '/messageTemplate/wxUpdate',
          data: this.form,
        }).then(response => {
              if (response.data.responseCode === 200) {
                this.dialogVisible = false;
                this.fetchData();
              } else {
                console.log(response.data.message);
              }
            }
        )
      }
    },
    del(id) {
      this.$confirm('确定删除该求职者？').then(() => {
        sendJsonPostRequest({
          url: '/sysWxUser/update',
          data: {'id': id, expire: 1},
        }).then(response => {
          if (response.data.responseCode === 200) {
            this.$message.success('删除成功');
            this.fetchData();
          }
        })
      }).catch(() => {
        this.$message.info('已取消删除');
      });
    },
    updateShow(row) {
      this.$router.push({
        path: '/appMain/editWxUser',
        query: {id: row.id}
      });
    },
    detailShow(row) {
      this.$router.push({
        path: '/appMain/detailWxUser',
        query: {id: row.id}
      });
    },
    search() {
      this.currentPage = 1;
      sendJsonPostRequest({
        url: '/sysWxUser/page',
        data: {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          realName: this.searchUserName,
          createBy: this.searchCreateBy
        },
      }).then(response => {
        if (response.data.responseCode === 200) {
          this.tableData = response.data.data.records;
        }
      })
    },
    restSearch() {
      this.searchUserName = '';
    },
    tableRowClassName({rowIndex}) {
      //tableRowClassName({row, rowIndex})
      if (rowIndex % 2 == 0) {
        return 'warning-row';
      } else {
        return 'success-row';
      }
    },
    goWxUserDetail(id) {
      this.$router.push({path: '/appMain/detailWxUser', query: {id: id}})
    }
  }
}
</script>


<style scoped>
/deep/ .el-table .warning-row {
  background: oldlace;
}

/deep/ .el-table .success-row {
  background: #f0f9eb;
}
</style>
