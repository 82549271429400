<template>
  <div>
    <el-row type="flex" :gutter="20" class="row-style">
      <el-col :xs="6" :sm="6" :md="6" :lg="4" :xl="3">
        <el-input v-model="searchUserName" placeholder="请输入用户名"></el-input>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="4" :xl="2">
        <el-button type="primary" @click="searchUser">搜索代理商</el-button>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="4" :xl="2">
        <el-button type="primary" @click="restSearch">重置搜索</el-button>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="4" :xl="2">
        <el-button type="primary" @click="addUser" style="text-align: center">新增代理商</el-button>
      </el-col>
    </el-row>
    <el-dialog
        title="设置密码"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :visible.sync="passwordDialogVisible"
        width="50%">
      <el-input v-model="pwd.password" type="password" placeholder="请输入密码"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="passwordDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="settingPasswordSubmit">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :title="this.handleType === 'add' ? '新增代理商' : '修改代理商'"
        :visible.sync="dialogVisible"
        width="50%">
      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item label="用户名">
          <template #label>
            <span style="color: red">*</span> 用户名
          </template>
          <el-input v-model="form.username" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label="手机号码">
          <template #label>
            <span style="color: red">*</span> 手机号码
          </template>
          <el-input v-model="form.phone" placeholder="请输入手机号码"></el-input>
        </el-form-item>
        <el-form-item v-show="handleType === 'add'">
          <template #label>
            <span style="color: red;">*</span>区域
          </template>
          <el-cascader
              style="width: 80%;"
              @change="handleCheckedNodes"
              v-model="form.areaId"
              :multiple="false"
              :options="treeData"
              :props="{ checkStrictly: true, label: 'label',value: 'id', children: 'children' }"
              clearable>
          </el-cascader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitForm">确定</el-button>
      </span>
    </el-dialog>

    <el-table
        :data="tableData" :row-class-name="tableRowClassName"
        style="width: 100%;overflow: scroll;">
      <el-table-column
          fixed
          prop="username"
          label="用户名">
      </el-table-column>
      <el-table-column
          fixed
          prop="phone"
          label="手机号码">
      </el-table-column>
      <el-table-column
          label="设置密码">
        <template v-slot="scope">
          <el-button @click="settingPassword(scope.row)" type="primary">设置密码</el-button>
        </template>
      </el-table-column>
      <el-table-column
          label="编辑">
        <template v-slot="scope">
          <el-button @click="updateUser(scope.row)" type="primary">编辑</el-button>
        </template>
      </el-table-column>
      <el-table-column
          label="删除">
        <template v-slot="scope">
          <el-button @click="deleteUser(scope.row)" type="danger">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        layout="prev, pager, next"
        :current-page="currentPage"
        :page-size="pageSize"
        :total="total"
        @current-change="handlePageChange">
    </el-pagination>
  </div>
</template>

<script>
//导入crud 请求方法
import {addUser, updateUser} from '@/pages/agent/agent'
import {sendJsonPostRequest} from "@/request/http";
//import md5 from 'js-md5';


export default {
  name: "AgentManagement",
  data() {
    return {
      treeData: [],
      selected: null,
      passwordDialogVisible: false,
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      dialogVisible: false,
      handleType: '',
      searchUserName: '',
      form: {
        userName: '',
        phone: '',
        areaId: ''
      },
      pwd: {
        id: '',
        password: ''
      }
    }
  },
  created() {
    this.fetchData();
    this.getTreeData();
  },
  methods: {
    handleCheckedNodes(data) {
      if (data.length !== 0) {
        this.form.areaId = data[data.length - 1];
      }
    },
    getTreeData() {
      sendJsonPostRequest({
        url: '/agent/areaTree',
        data: {},
      }).then(response => {
        if (response.data.responseCode === 200) {
          this.treeData = response.data.data;
        } else {
          console.log(response.data.responseMessage);
        }
      })
    },
    settingPassword(row) {
      this.pwd.id = row.id;
      this.passwordDialogVisible = true;
    },
    settingPasswordSubmit() {
      sendJsonPostRequest({
        url: '/user/settingPassword',
        data: {
          id: this.pwd.id,
          password: this.pwd.password
        },
      }).then(response => {
        if (response.data.responseCode === 200) {
          this.$message.success("设置成功");
        } else {
          this.$message.error("设置成功");
        }
      })
      this.pwd = {};
      this.passwordDialogVisible = false;
    },
    fetchData() {
      sendJsonPostRequest({
        url: '/user/list',
        data: {'pageNum': this.currentPage, 'pageSize': this.pageSize},
      }).then(response => {
        this.tableData = response.data.data.list;
        this.total = response.data.data.total;
      })
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    addUser() {
      // 显示对话框以添加新项
      this.dialogVisible = true;
      this.handleType = 'add';
      // 重置表单数据
      this.form = {};
    },
    submitForm() {
      if (this.handleType === 'add') {
        addUser({
          data: this.form,
        }).then(response => {
              if (response.data.responseCode === 200) {
                this.dialogVisible = false;
                this.fetchData();
              } else if (response.data.responseCode === 602) {
                this.$message({
                  message: response.data.responseMessage,
                  type: 'warning'
                });
              } else {
                console.log(response.data.responseMessage);
              }
              this.form = {};
              this.dialogVisible = false;
            }
        )
      } else if (this.handleType === 'update') {
        updateUser({
          data: this.form,
        }).then(response => {
              if (response.data.responseCode === 200) {
                this.dialogVisible = false;
                this.fetchData();
              } else {
                console.log(response.data.message);
              }
            }
        )
      }
    },
    deleteUser(row) {
      this.$confirm('确定删除该用户？').then(() => {
        sendJsonPostRequest({
          url: '/user/delete',
          data: {'id': row.id},
        }).then(response => {
          if (response.data.responseCode === 200) {
            this.$message.success('删除成功');
            this.fetchData();
          }
        })
      }).catch(() => {
        // 用户点击 "取消" 或其他异常的处理
        this.$message({
          message: '操作已取消',
          type: 'info'
        });
      });
    },
    updateUser(row) {
      // 显示对话框以添加新项
      this.dialogVisible = true;
      this.handleType = 'update';
      // 重置表单数据
      this.form = {
        id: row.id,
        username: row.username,
        phone: row.phone,
        password: row.password,
      };
    },
    searchUser() {
      sendJsonPostRequest({
        url: '/user/list',
        data: {'username': this.searchUserName},
      }).then(response => {
        this.tableData = response.data.data.list;
      })
    },
    restSearch() {
      this.searchUserName = '';
    },
    tableRowClassName({rowIndex}) {
      //tableRowClassName({row, rowIndex})
      if (rowIndex % 2 == 0) {
        return 'warning-row';
      } else {
        return 'success-row';
      }
    }
  },

}
</script>


<style scoped>
/deep/ .el-table .warning-row {
  background: oldlace;
}

/deep/ .el-table .success-row {
  background: #f0f9eb;
}
</style>
