import {sendFormPostRequest, sendJsonPostRequest} from '@/request/http';

export function getUserList(config) {
    config.url = '/user/list';
    return sendFormPostRequest(config);
}

export function updateUser(config) {
    config.url = '/user/edit';
    return sendJsonPostRequest(config);
}

export function deleteUser(config) {
    config.url = '/user/remove';
    return sendFormPostRequest(config);
}

export function addUser(config) {
    config.url = '/user/add';
    return sendJsonPostRequest(config);
}

export function roleSelectList(config) {
    config.url = '/role/list';
    return sendJsonPostRequest(config);
}
