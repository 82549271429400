import { render, staticRenderFns } from "./WxSendSomeManagement.vue?vue&type=template&id=03168e1c&scoped=true"
import script from "./WxSendSomeManagement.vue?vue&type=script&lang=js"
export * from "./WxSendSomeManagement.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03168e1c",
  null
  
)

export default component.exports