<template>
  <div>
    <el-row type="flex" :gutter="20" class="row-style">
      <el-col :xs="6" :sm="6" :md="6" :lg="4" :xl="3">
        <el-input v-model="searchCompanyName" placeholder="请输入公司名"></el-input>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="4" :xl="2">
        <el-button type="primary" @click="searchCompany">搜索公司</el-button>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="4" :xl="2">
        <el-button type="primary" @click="restSearch">重置搜索</el-button>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="4" :xl="2">
        <el-button type="primary" @click="addCompany" style="text-align: center">新增公司</el-button>
      </el-col>
    </el-row>
    <el-dialog
        title="提示"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :visible.sync="delVisible"
        width="30%">
      <span>删除公司</span>
      <span slot="footer" class="dialog-footer">
    <el-button @click="delVisible = false">取 消</el-button>
    <el-button type="primary" @click="deleteCompany()">确 定</el-button>
  </span>
    </el-dialog>
    <el-dialog
        :title="this.handleType === 'add' ? '新增公司' : '修改公司'"
        :visible.sync="dialogVisible"
        @close="closeDialog"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        width="50%">
      <el-form ref="form" :model="form" label-width="7rem">
        <el-form-item label="公司名">
          <template #label>
            <span style="color: red">*</span> 公司名
          </template>
          <el-input required clearable v-model="form.companyName"></el-input>
        </el-form-item>
        <el-form-item label="公司logo">
          <template #label>
            <span style="color: red">*</span> 公司logo
          </template>
          <el-upload
              class="upload-demo"
              action="https://www.ljcsfw.com/file/upload/"
              :before-remove="beforeRemove"
              multiple
              list-type="picture-card"
              :limit="1"
              :on-exceed="handleExceed"
              :on-success="handleSuccess"
              :on-error="handleError"
              :file-list="fileList">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传1张jpg/png文件</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="公司性质">
          <template #label>
            <span style="color: red">*</span> 公司性质
          </template>
          <el-select v-model="form.companyNature" placeholder="请选择">
            <el-option
                v-for="item in companyNatureList"
                :key="item.dictValue"
                :label="item.dictLabel"
                :value="item.dictValue">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="行业类别" v-show="this.handleType==='add'">
          <template #label>
            <span style="color: red">*</span> 行业类别
          </template>
          <el-cascader style="width: 80%;"
                       v-model="temp"
                       :options="companyIndustryTree"
                       :props="{ expandTrigger: 'hover', label: 'label',value: 'id', children: 'children' }"
                       @change="companyIndustryTreeChange"></el-cascader>
        </el-form-item>
        <el-form-item label="行业类别" v-show="this.handleType==='update'">
          <el-input :disabled="true" v-model="form.industryType"></el-input>
        </el-form-item>
        <el-form-item label="联系方式">
          <template #label>
            <span style="color: red">*</span> 联系方式
          </template>
          <el-input required v-model="form.phone"></el-input>
        </el-form-item>
        <!--        <el-form-item label="邮箱">
                  <el-input v-model="form.email"></el-input>
                </el-form-item>-->
        <el-form-item label="所在城市" v-show="this.handleType === 'add'">
          <template #label>
            <span style="color: red">*</span> 所在城市
          </template>
          <area-cascader size="large" type="text" v-model="selected" :data="pca" style="line-height:1rem;"
                         @change="handleChange"></area-cascader>
        </el-form-item>
        <el-form-item label="详细地址">
          <template #label>
            <span style="color: red">*</span> 详细地址
          </template>
          <el-input required v-model="form.address"></el-input>
        </el-form-item>
        <!--        <el-form-item label="公司logo">
                  <el-input v-model="form.logoUrl"></el-input>
                </el-form-item>-->
        <el-form-item label="员工数量">
          <el-input v-model="form.employeesNumber" placeholder="1000人"></el-input>
        </el-form-item>
        <el-form-item label="公司简介">
          <template #label>
            <span style="color: red">*</span> 公司简介
          </template>
          <el-input v-model="form.description" type="textarea" placeholder="公司简介"></el-input>
        </el-form-item>
        <el-form-item label="工作时间">
          <template #label>
            <span style="color: red">*</span> 工作时间
          </template>
          <el-input v-model="form.businessHours" placeholder="9:00-18:00"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="submitForm">确定</el-button>
      </span>
    </el-dialog>

    <el-table
        :data="tableData" :row-class-name="tableRowClassName"
        style="width: 100%;overflow: scroll;margin-top: 1rem;">
      <el-table-column
          fixed
          prop="companyName"
          label="公司名">
      </el-table-column>
      <el-table-column
          fixed
          show-overflow-tooltip
          prop="description"
          label="公司简介">
      </el-table-column>
      <el-table-column
          fixed
          prop="companyNature"
          label="公司性质">
        <template v-slot="scope">
          {{ companyNatureDictMap[scope.row.companyNature] }}
        </template>
      </el-table-column>
      <el-table-column
          fixed
          prop="industryType"
          show-overflow-tooltip
          label="行业类别">
      </el-table-column>
      <el-table-column
          fixed
          prop="city"
          label="城市">
      </el-table-column>
      <el-table-column
          fixed
          prop="employeesNumber"
          label="员工数量">
      </el-table-column>
      <!--      <el-table-column
                fixed
                prop="email"
                label="邮箱">
            </el-table-column>-->
      <el-table-column
          fixed
          prop="jobActiveCount"
          label="在招职位数">
      </el-table-column>
      <el-table-column
          fixed
          prop="phone"
          label="联系方式">
      </el-table-column>
      <!--      <el-table-column
                label="详情">
              <template v-slot="scope">
                <el-button @click="goCompanyDetail(scope.row.id)" type="primary">详情</el-button>
              </template>
            </el-table-column>-->
      <el-table-column
          label="编辑">
        <template v-slot="scope">
          <el-button @click="updateCompany(scope.row)" type="primary">编辑</el-button>
        </template>
      </el-table-column>
      <el-table-column
          label="删除">
        <template v-slot="scope">
          <el-button @click="showDialogCompany(scope.row)" type="danger">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        layout="prev, pager, next"
        :current-page="currentPage"
        :page-size="pageSize"
        :total="total"
        @current-change="handlePageChange">
    </el-pagination>
  </div>
</template>

<script>

import {addCompany, companyPage, updateCompany} from "@/pages/company/company";
import {sendJsonPostRequest} from "@/request/http";
import {pca} from 'area-data';

export default {
  name: "CompanyManagement",
  data() {
    return {
      temp: null,
      companyIndustryTree: [],
      fileList: [],
      companyNatureList: [],
      selected: [],
      pca: pca,
      tableData: [], // 用于存储当前页的数据
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的数据项数
      total: 0, // 总数据项数，用于分页组件显示总页数
      dialogVisible: false,
      handleType: '',
      searchCompanyName: '',
      form: {},
      delId: null,
      delVisible: false,
      companyNatureDictMap: {},
      industryTypeDictMap: {},
      industryTypeList: []
    }
  },
  created() {
    this.getDictList();
    this.fetchData();
    this.getCompanyIndustryTree();
  },
  methods: {
    companyIndustryTreeChange(node) {
      this.form.industryTypeList = node
    },
    getCompanyIndustryTree() {
      sendJsonPostRequest({
        url: '/company/companyIndustryTree',
        data: {},
      }).then(response => {
        if (response.data.responseCode === 200) {
          this.companyIndustryTree = response.data.data;
        }
      });
    },
    closeDialog() {
      this.dialogVisible = false;
      this.fileList = [];
    },
    handleSuccess(response) {
      this.form.logoUrl = response.data.fileUrl;
    },
    handleError(err, file, fileList) {
      console.error('Upload failed:');
      console.error('Error:', err);
      console.error('File:', file);
      console.error('Current file list:', fileList);
    },
    handleExceed(files, fileList) {
      console.log(files, fileList);
      this.$message.warning('只能上传一个文件');
    },
    beforeRemove(file, fileList) {
      console.log(fileList);
      return this.$confirm(`确定移除`);
    },
    handleChange() {
      if (this.selected.length >= 2) {
        this.form.city = this.selected.join('/');
        this.selected = [];
      }
    },
    getDictList() {
      this.companyNatureDictMap = JSON.parse(localStorage.getItem('companyNature'));
      this.industryTypeDictMap = JSON.parse(localStorage.getItem('industryType'));
      this.industryTypeList = JSON.parse(localStorage.getItem('industryTypeList'));
      this.companyNatureList = JSON.parse(localStorage.getItem('companyNatureList'));
    },
    showDialogCompany(row) {
      this.delVisible = true;
      this.delId = row.id;
    },
    fetchData() {
      companyPage({
        data: {'pageNum': this.currentPage, 'pageSize': this.pageSize},
      }).then(response => {
        if (response.data.responseCode === 200) {
          this.tableData = response.data.data.records;
          this.total = response.data.data.total;
        } else {
          console.log(response.data.responseMessage);
        }
      }).catch(error => {
        console.error("Error fetching data:", error);
      });
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    addCompany() {
      // 显示对话框以添加新项
      this.dialogVisible = true;
      this.handleType = 'add';
      // 重置表单数据
      this.form = {};
      // this.$router.push({path: '/appMain/addCompany'});
    },
    submitForm() {
      if (this.handleType === 'add') {
        addCompany({
          data: this.form,
        }).then(response => {
              if (response.data.responseCode === 200) {
                this.dialogVisible = false;
                this.fetchData();
              } else if (response.data.responseCode == 602) {
                this.$message.error(response.data.responseMessage);
              } else {
                console.log(response.data.message);
              }
            }
        )
      } else if (this.handleType === 'update') {
        updateCompany({
          data: this.form,
        }).then(response => {
              if (response.data.responseCode === 200) {
                this.dialogVisible = false;
                this.fetchData();
              } else {
                console.log(response.data.message);
              }
            }
        )
      }
      this.form = {};
      this.fileList = [];
    },
    deleteCompany() {
      //检查公司下面是否还有职位没有删除
      sendJsonPostRequest({
        url: '/company/deleteCompany',
        data: {'id': this.delId},
      }).then(response => {
        if (response.data.responseCode !== 200) {
          this.$message.error(response.data.responseMessage);
        } else {
          this.$message({
            message: '删除成功',
            type: 'warning'
          });
        }
        this.delVisible = false;
        this.fetchData();
        this.delId = null;
      });
    },
    updateCompany(row) {
      // 显示对话框以添加新项
      if (row.logoUrl) {
        this.fileList = [{
          name: row.logoUrl,
          url: row.logoUrl
        }];
      }
      this.dialogVisible = true;
      this.handleType = 'update';
      // 重置表单数据
      this.form = Object.assign({}, row);
    },
    searchCompany() {
      companyPage({
        data: {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          companyName: this.searchCompanyName,
        },
      }).then(response => {
        if (response.data.responseCode === 200) {
          this.tableData = response.data.data.records;
        }
      })
    },
    restSearch() {
      this.searchCompanyName = '';
    },
    tableRowClassName({rowIndex}) {
      //tableRowClassName({row, rowIndex})
      if (rowIndex % 2 == 0) {
        return 'warning-row';
      } else {
        return 'success-row';
      }
    },
    goCompanyDetail(id) {
      this.$router.push({path: '/appMain/companyDetail', query: {id: id}})
    }
  }
}
</script>


<style scoped>
/deep/ .el-table .warning-row {
  background: oldlace;
}

/deep/ .el-table .success-row {
  background: #f0f9eb;
}

.area-select .area-selected-trigger {
  padding: 1px 5px;
}
</style>
