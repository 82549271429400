<template>
  <div>
    <el-row>
      <el-col :span="8">
        <el-button type="primary" @click="goBack">返回</el-button>
      </el-col>
      <el-col :span="8">
        <h1>自定义发送微信消息提醒</h1>
      </el-col>
      <el-col :span="8">
        <el-button type="primary" @click="submitForm">确认发送</el-button>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-row>
      <el-col :span="4" style="display: flex;justify-content: center;align-items: center;">
        <span>模板名称</span>
      </el-col>
      <el-col :span="4">
        <span>模板内容</span>
      </el-col>
    </el-row>
    <el-row style="margin-top: 1rem;">
      <el-col :span="4" style="display: flex;justify-content: center;align-items: center;">
        <h1>{{ form.title }}</h1>
      </el-col>
      <el-col :span="12">
        <el-input
            type="textarea"
            :rows="6"
            disabled
            v-model="form.content">
        </el-input>
      </el-col>
    </el-row>
    <el-row style="margin-top: 1rem;">
      <el-col :span="4" style="display: flex;justify-content: right;align-items: center;">
        <span style="color: red">*</span>
      </el-col>
      <el-col :span="12">
        <el-input
            type="textarea"
            :rows="12"
            clearable
            placeholder="请输入电话号码，多个电话号码用英文逗号隔开"
            v-model="phones">
        </el-input>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {sendJsonPostRequest} from "@/request/http";

export default {
  name: "WxSendSomeManagement",
  data() {
    return {
      form: {},
      phones: ''
    }
  },
  methods: {
    submitForm() {
      const phoneRegex = /^(\+?\d{1,3}[- ]?)?(\d{10}|\d{3}[- ]\d{3}[- ]\d{4})(,(\s)?(\+?\d{1,3}[- ]?)?(\d{10}|\d{3}[- ]\d{3}[- ]\d{4}))*$/;
      if (!phoneRegex.test(this.phones)) {
        this.$message.error("输入格式错误！请输入正确的电话号码，用英文逗号分隔多个号码。");
        return;
      }
      this.$confirm('确定发送吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            let strings = this.phones.split(',');
            sendJsonPostRequest({
              url: '/messageTemplate/wxSendMessage',
              data: {
                id: this.$route.query.id,
                phones: strings
              }
            }).then(response => {
              if (response.data.responseCode === 200) {
                this.$message.success("发送成功");
                this.$router.back();
              } else {
                this.$message.error("发送失败");
              }
            })
          })
    },
    goBack() {
      this.$router.back();
    }
    ,
    getDetail() {
      sendJsonPostRequest({
        url: '/messageTemplate/detail',
        data: {id: this.$route.query.id},
      }).then(response => {
        if (response.data.responseCode === 200) {
          this.form = response.data.data;
        } else {
          this.$message.error("网络异常");
        }
      });
    }
    ,
  },
  created() {
    this.getDetail();
  }
}
</script>

<style scoped>

</style>
