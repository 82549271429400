<template>
  <div class="container">
    <el-row>
      <el-col :span="10">
        <el-button type="primary" @click="back">返回</el-button>
        <el-button type="primary" @click="submitForm" style="margin-left: 10rem">提交</el-button>
      </el-col>
      <el-col :span="12">
        <h1>职位新增</h1>
      </el-col>
    </el-row>
    <el-row style="margin-top: 2rem;">
      <el-form ref="form" :model="form" label-width="7rem">
        <el-col :span="8">
          <el-form-item label="公司名">
            <template #label>
              <span style="color: red">*</span> 公司
            </template>
            <el-select v-model="form.companyId" placeholder="请选择">
              <el-option
                  v-for="item in companyDropDownList"
                  :key="item.id"
                  :label="item.companyName"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职位名">
            <template #label>
              <span style="color: red">*</span> 职位名
            </template>
            <el-input v-model="form.jobName" placeholder="职位名"></el-input>
          </el-form-item>
          <el-form-item label="行业类型：">
            <template #label>
              <span style="color: red">*</span> 行业类型：
            </template>
            <el-select v-model="form.jobType" placeholder="请选择">
              <el-option
                  v-for="item in jobTypeList"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="工资待遇">
            <template #label>
              <span style="color: red">*</span> 工资待遇
            </template>
            <el-input-number v-model="form.salaryMin" controls-position="right" :min="1000"
                             :max="10000000" :step="500" step-strictly></el-input-number>
            <span style="margin: 0 1rem">至</span>
            <el-input-number v-model="form.salaryMax" controls-position="right" :min="2000"
                             :max="10000000" :step="500" step-strictly></el-input-number>
          </el-form-item>
          <el-form-item label="招聘人数">
            <template #label>
              <span style="color: red">*</span> 招聘人数
            </template>
            <el-input-number v-model="form.invitePeople" :min="1" :max="10000"
                             label="描述文字"></el-input-number>
          </el-form-item>
          <el-form-item label="经验">
            <template #label>
              <span style="color: red">*</span> 经验要求
            </template>
            <el-input v-model="form.experience" placeholder="1-3年/无要求"></el-input>
          </el-form-item>
          <el-form-item label="社保待遇">
            <template #label>
              <span style="color: red">*</span> 社保待遇
            </template>
            <el-input v-model="form.socialSecurity" placeholder="五险一金"></el-input>
          </el-form-item>
          <el-form-item label="联系方式">
            <el-input required v-model="form.phone" placeholder="联系方式"></el-input>
          </el-form-item>

          <el-form-item label="工作时间">
            <template #label>
              <span style="color: red">*</span> 工作时间
            </template>
            <el-input v-model="form.businessHours" placeholder="9:00-18:00"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10" style="margin-left: 1rem">
          <el-form-item label="工作城市">
            <template #label>
              <span style="color: red">*</span> 所在城市
            </template>
            <area-cascader size="large" type="text" v-model="selected" :data="pca" style="line-height:1rem;"
                           @change="handleChange"></area-cascader>
          </el-form-item>
          <el-form-item label="详细工作地址">
            <template #label>
              <span style="color: red">*</span> 详细工作地址
            </template>
            <el-input v-model="form.jobAddress" placeholder="详细工作地址"></el-input>
          </el-form-item>
          <el-form-item>
            <template #label>
              <span style="color: red">*</span> 学历
            </template>
            <el-select v-model="form.education" placeholder="请选择">
              <el-option
                  v-for="item in educationList"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="工作内容">
            <template #label>
              <span style="color: red">*</span>工作内容:
            </template>
            <template>
              <div>
                <!-- 富文本编辑器 -->
                <quillEditor
                    @change="onEditorChange($event)"
                    v-model="form.workContent"
                    :options="editorOptions"
                    style="height: 200px;">
                </quillEditor>
              </div>
            </template>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>

<script>
import {sendJsonPostRequest} from "@/request/http";
import {pca} from 'area-data';
import {quillEditor} from 'vue-quill-editor';

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

export default {
  name: "AddCompanyJobManagement",
  components: {quillEditor},
  data() {
    return {
      content: '<p>这是初始内容</p>',
      editorOptions: {
        placeholder: '请输入工作内容...',
        theme: 'snow',
        modules: {
          toolbar: [
            {list: 'bullet'}, {list: 'ordered'}
          ],
        },
      },
      selected: null,
      jobTypeList: [],
      educationList: [],
      pca: pca,
      form: {
        companyId: '',
        jobType: '',
        education: '',
        jobAddress: '',
        phone: '',
        businessHours: '9:00-18:00',
        socialSecurity: '五险一金',
        jobName: '',
        invitePeople: 1,
        salaryMax: 1000,
        salaryMin: 2000,
        experience: '1-3年',
        workContent: '<ul><li>服从领导安排</li><li>负责产品售后</li><li>负责接待来访客户</li></ul>',
      },
      companyDropDownList: []
    }
  },
  methods: {
    onEditorChange(editor) {
      this.form.workContent = editor.html;
    },
    handleChange() {
      if (this.selected.length >= 2) {
        this.form.city = this.selected.join('/');
        this.selected = [];
      }
    },
    submitForm() {
   /*   const formValues = Object.values(this.form);
      // 检查是否有未填写的字段
      const hasEmptyField = formValues.some(value =>
          value === null || value === undefined || value === ''
      );
      if (hasEmptyField) {
        this.$message.error('请填写所有必填字段');
        return false;
      }*/
      sendJsonPostRequest({
        url: '/companyJob/add',
        data: this.form
      }).then(response => {
        if (response.data.responseCode === 200) {
          this.$message.success('添加成功');
          this.$router.push({path: '/appMain/companyJob'});
        } else if (response.data.responseCode === 602) {
          this.$message.error(response.data.responseMessage);
        } else {
          this.$message.error('添加失败');
        }
      })
    },
    getDictMap() {
      this.jobTypeList = JSON.parse(localStorage.getItem('jobTypeList'));
      this.educationList = JSON.parse(localStorage.getItem('educationList'));
    },
    back() {
      this.$router.go(-1);
    },
    getCompanyDropDownList() {
      sendJsonPostRequest({
        url: '/company/dropDownList'
      }).then(response => {
        if (response.data.responseCode === 200) {
          this.companyDropDownList = response.data.data;
        } else {
          console.log(response.data.responseMessage);
        }
      })
    }
  },
  created() {
    this.getCompanyDropDownList();
    this.getDictMap();
  }
}
</script>

<style scoped>

</style>
