<template>
  <div>
    <el-row type="flex" :gutter="20" class="row-style">
      <el-col :xs="6" :sm="6" :md="6" :lg="4" :xl="3">
        <el-input v-model="searchTitleName" clearable placeholder="请输入标题名"></el-input>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="4" :xl="2">
        <el-button type="primary" @click="search">搜索模板</el-button>
      </el-col>
      <!--      <el-col :xs="6" :sm="6" :md="6" :lg="4" :xl="2">
              <el-button type="primary" @click="restSearch">一键通知</el-button>
            </el-col>-->
      <el-col :xs="6" :sm="6" :md="6" :lg="4" :xl="2">
        <el-button type="primary" @click="addShow" style="text-align: center">新增模板</el-button>
      </el-col>
    </el-row>
    <el-dialog
        title="提示"
        :visible.sync="delVisible"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        width="30%">
      <span>删除模板</span>
      <span slot="footer" class="dialog-footer">
    <el-button @click="delVisible = false">取 消</el-button>
    <el-button type="primary" @click="del">确 定</el-button>
  </span>
    </el-dialog>
    <el-dialog
        :title="this.handleType === 'add' ? '新增模板' : '修改模板'"
        :visible.sync="dialogVisible"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        width="50%">
      <el-form ref="form" :model="form" label-width="7rem">
        <el-form-item label="微信消息标题名">
          <template #label>
            <span style="color: red">*</span> 标题名
          </template>
          <el-input required clearable v-model="form.title"></el-input>
        </el-form-item>
      </el-form>
      <el-form ref="form" :model="form" label-width="7rem">
        <el-form-item label="微信消息内容">
          <template #label>
            <span style="color: red">*</span> 微信消息内容
          </template>
          <el-input type="textarea"
                    :rows="6" required clearable v-model="form.content"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitForm">确定</el-button>
      </span>
    </el-dialog>

    <el-table
        :data="tableData" :row-class-name="tableRowClassName"
        style="width: 100%;overflow: scroll;margin-top: 1rem;">
      <el-table-column
          fixed
          prop="title"
          label="标题">
      </el-table-column>
      <el-table-column
          fixed
          prop="content"
          label="内容">
      </el-table-column>
      <el-table-column
          label="通知所有人">
        <template v-slot="scope">
          <el-button @click="sendAllMessage(scope.row)" type="primary">通知所有人</el-button>
        </template>
      </el-table-column>
      <el-table-column
          label="通知部分人">
        <template v-slot="scope">
          <el-button @click="sendSomeMessage(scope.row)" type="primary">通知部分人</el-button>
        </template>
      </el-table-column>
      <el-table-column
          label="编辑">
        <template v-slot="scope">
          <el-button @click="updateShow(scope.row)" type="primary">编辑</el-button>
        </template>
      </el-table-column>
      <el-table-column
          label="删除">
        <template v-slot="scope">
          <el-button @click="deleteShow(scope.row)" type="danger">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        layout="prev, pager, next"
        :current-page="currentPage"
        :page-size="pageSize"
        :total="total"
        @current-change="handlePageChange">
    </el-pagination>
  </div>
</template>

<script>

import {sendJsonPostRequest} from "@/request/http";

export default {
  name: "WxMessageTemplateManagement",
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      dialogVisible: false,
      handleType: '',
      searchTitleName: '',
      form: {},
      delId: null,
      delVisible: false
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    sendAllMessage(row) {
      this.$confirm('发送微信消息提醒给所有求职者用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            sendJsonPostRequest({
              url: '/messageTemplate/wxSendMessage',
              data: {'id': row.id},
            }).then(response => {
              if (response.data.responseCode === 200) {
                this.$message({
                  message: '发送成功',
                  type: 'success'
                });
              } else {
                this.$message({
                  message: '发送失败',
                  type: 'failed'
                });
              }
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            });
          });

    },
    sendSomeMessage(row) {
      this.$router.push({
        path: '/appMain/wxSendSome',
        query: {
          id: row.id
        }
      })
    },
    deleteShow(row) {
      this.delVisible = true;
      this.delId = row.id;
    },
    fetchData() {
      sendJsonPostRequest({
        url: '/messageTemplate/wxPage',
        data: {'pageNum': this.currentPage, 'pageSize': this.pageSize},
      }).then(response => {
        if (response.data.responseCode === 200) {
          this.tableData = response.data.data.records;
          this.total = response.data.data.total;
        } else {
          console.log(response.data.message);
        }
      })
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    addShow() {
      // 显示对话框以添加新项
      this.dialogVisible = true;
      this.handleType = 'add';
      // 重置表单数据
      this.form = {};
    },
    submitForm() {
      if (this.handleType === 'add') {
        sendJsonPostRequest({
          url: '/messageTemplate/wxSave',
          data: this.form,
        }).then(response => {
          if (response.data.responseCode === 200) {
            this.dialogVisible = false;
            this.fetchData();
          } else {
            console.log(response.data.message);
          }
        })
      } else if (this.handleType === 'update') {
        sendJsonPostRequest({
          url: '/messageTemplate/wxUpdate',
          data: this.form,
        }).then(response => {
              if (response.data.responseCode === 200) {
                this.dialogVisible = false;
                this.fetchData();
              } else {
                console.log(response.data.message);
              }
            }
        )
      }
    },
    del() {
      sendJsonPostRequest({
        url: '/messageTemplate/wxUpdate',
        data: {'id': this.delId, expire: 1},
      }).then(response => {
        if (response.data.responseCode === 200) {
          this.$message.success = '删除成功';
          this.delVisible = false;
          this.fetchData();
        }
      })
    },
    updateShow(row) {
      // 显示对话框以添加新项
      this.dialogVisible = true;
      this.handleType = 'update';
      // 重置表单数据
      this.form = Object.assign({}, row);
    },
    search() {
      this.currentPage = 1;
      sendJsonPostRequest({
        url: '/messageTemplate/wxPage',
        data: {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          title: this.searchTitleName,
        },
      }).then(response => {
        if (response.data.responseCode === 200) {
          this.tableData = response.data.data.records;
        }
      })
    },
    restSearch() {
      this.searchWxTemplateName = '';
    },
    tableRowClassName({rowIndex}) {
      //tableRowClassName({row, rowIndex})
      if (rowIndex % 2 == 0) {
        return 'warning-row';
      } else {
        return 'success-row';
      }
    },
    goCompanyDetail(id) {
      this.$router.push({path: '/appMain/companyDetail', query: {id: id}})
    }
  }
}
</script>


<style scoped>
/deep/ .el-table .warning-row {
  background: oldlace;
}

/deep/ .el-table .success-row {
  background: #f0f9eb;
}
</style>
